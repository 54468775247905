import slide1 from 'assets/instruction/ios/slide-1.png'
import slide2 from 'assets/instruction/ios/slide-2.png'
import slide3 from 'assets/instruction/ios/slide-3.png'
import slide4 from 'assets/instruction/ios/slide-4.png'
import slide5 from 'assets/instruction/ios/slide-5.png'
import { SliderItem } from '../type'

export const IOS_SLIDER_ITEMS: SliderItem[] = [
  {
    image: slide1,
    description: 'Перейдите на сайт app.sanek.io',
  },
  {
    image: slide2,
    description: 'Нажмите на кнопку “Поделиться”',
  },
  {
    image: slide3,
    description: 'На открывшейся вкладке нажмите на кнопку “На экран Домой” ',
  },
  {
    image: slide4,
    description: 'Нажмите “Добавить”',
  },
  {
    image: slide5,
    description: 'Ура! Приложение утсановлено и добавлено на экран “Домой”',
  },
]
