import { JSX, useEffect, useState } from 'react'
import style from './intro-register.module.css'
import ManTall from 'assets/man_tall.png'
import StrokeGray from 'assets/stroke-gray.svg'
import StrokeYellow from 'assets/stroke-yellow.svg'
import {
  REGISTER_BENEFITS,
  TRegisterBenefit,
} from './intro-register-benefits.const.tsx'
import Pointer from 'assets/pointer.svg'
import { observer } from 'mobx-react-lite'
import { userStore } from 'store/user.store.ts'
import { router } from 'router'
import { SanekRoute } from 'router/router.enums.ts'
import { CenteredLoader } from 'components/common/centered-loader'
import { SanekButton } from 'ui/sanek-button'
import { InstrustionInfoPopup } from './components/instruction-info-popup/instruction-info-popup.component.tsx'

export const IntroRegisterSmsPage = observer((): JSX.Element => {
  const { isLoading } = userStore
  const [showInstruction, setShowInstruction] = useState(false)

  const isPWAInstalled = () => {
    return window.matchMedia('(display-mode: standalone)').matches
  }

  const goToSMSPage = async () => {
    await router.navigate(SanekRoute.INTRO_REGISTER_SMS_PHONE)
  }

  const navigateToInstrution = async () => {
    await router.navigate(SanekRoute.INSTRUCTION)
  }

  useEffect(() => {
    const result = isPWAInstalled()

    if (result) {
      setShowInstruction(false)
    } else {
      setShowInstruction(true)
    }
  }, [])

  if (isLoading) {
    return <CenteredLoader />
  }
  return (
    <>
      {showInstruction && (
        <InstrustionInfoPopup
          title="Упс..."
          description="Санёк заметил, что ты не установил приложение"
          descriptionBold="Сохрании текущую страницу на экран “Домой”"
          onConfirm={navigateToInstrution}
          onCancel={() => setShowInstruction(false)}
        />
      )}
      <div className={style.registerPage}>
        <div className={style.imgBgr}>
          <img className={style.manImg} src={ManTall} alt="Санёк" />
          <div className={style.pointer}>
            <p>САНЁК</p>
            <img src={Pointer} />
          </div>

          <img className={style.strokeImg} src={StrokeGray} />
        </div>
        <div className={style.benefitsBlock}>
          <p className={style.title}>Почему именно Санёк?</p>
          <div>
            {REGISTER_BENEFITS.map((benefit: TRegisterBenefit) => (
              <div key={benefit.id} className={style.benefit}>
                <div className={style.iconWrapper}>
                  <img src={benefit.icon} />
                </div>
                <p>{benefit.text}</p>
              </div>
            ))}
          </div>
          <img className={style.strokeYellow} src={StrokeYellow} width="100%" />
          <div className={style.widgetWrapper}>
            <SanekButton onClick={goToSMSPage}>Войти</SanekButton>
          </div>
        </div>
      </div>
    </>
  )
})
