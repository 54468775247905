import { PickerValue } from 'antd-mobile/es/components/picker-view'
import { TimeslotModel } from 'api/models/timeslot.model.ts'
import { TIME_TEMPLATE } from 'const/date.const.ts'
import dayjs, { Dayjs } from 'dayjs'
import {
  TPickerColumnData,
  TPickerColumns,
  TPickerTimeslot,
} from 'types/time-picker.types.ts'
import { TIME_PICKER_COLUMNS } from '../const/time.const.ts'

export function toPickerTimeslot(timeslot: TimeslotModel): TPickerTimeslot {
  return {
    id: String(timeslot.id),
    date: timeslot.date,
    timeFrom: timeslot.timeFrom,
    timeTo: timeslot.timeTo,
  }
}

export function getPickerTimeslots(
  timeslots?: Array<TimeslotModel> | null
): Array<TPickerTimeslot> {
  return timeslots?.map(toPickerTimeslot) || []
}

export function getTimeValue(value: Array<PickerValue>): Dayjs {
  if (value.length === 0) {
    return dayjs()
  }
  const hour = Number(value[0])
  const minute = Number(value[1])

  return dayjs().hour(hour).minute(minute)
}

export function getTimeLabel(
  time: Dayjs | null,
  defaultValue = 'Время'
): string {
  if (!time) {
    return defaultValue
  }

  return time.format(TIME_TEMPLATE)
}

export function getPickerValue(
  time: Dayjs | null,
  selectedDate?: Dayjs | null
): Array<PickerValue> {
  const newDate = dayjs(selectedDate)
  if (!newDate.isSame(dayjs(), 'day') && !time) {
    return [10, 0]
  }
  if (!time) {
    return []
  }

  return [time.hour(), time.minute()]
}

export function getTimePickerFromColumns(
  minDateTime: Dayjs,
  maxDateTime: Dayjs,
  selectedHour: number,
  timeTo?: Dayjs | null
): TPickerColumns {
  let [hours, minutes] = [...TIME_PICKER_COLUMNS]

  const minAvailableHour = minDateTime.hour()
  const maxAvailableHour = maxDateTime.hour()

  hours = hours.filter((item: TPickerColumnData) =>
    minDateTime.minute() >= 45
      ? item.value >= minDateTime.add(1, 'h').hour() &&
        item.value <= maxAvailableHour
      : item.value >= minAvailableHour && item.value <= maxAvailableHour
  )

  minutes = minutes.filter((item: TPickerColumnData) =>
    (selectedHour === minAvailableHour && minDateTime.minute() >= 45) ||
    selectedHour > minAvailableHour
      ? true
      : item.value >= minDateTime.minute()
  )

  if (timeTo) {
    hours = hours.filter((item: TPickerColumnData) =>
      timeTo.minute() === 0
        ? item.value < timeTo.hour()
        : item.value <= timeTo.hour()
    )

    minutes = minutes.filter((item: TPickerColumnData) =>
      selectedHour === timeTo.hour() ? item.value < timeTo.minute() : true
    )
  }

  return [hours, minutes]
}

export function getTimePickerToColumns(
  minDateTime: Dayjs,
  maxDateTime: Dayjs,
  selectedHour: number,
  timeFrom: Dayjs
): TPickerColumns {
  let [hours, minutes] = [...TIME_PICKER_COLUMNS]

  const minAvailableHour = minDateTime.hour()
  const maxAvailableHour = maxDateTime.hour()

  hours = hours.filter((item: TPickerColumnData) =>
    item.value === timeFrom.hour() && timeFrom.minute() >= 45
      ? item.value >= timeFrom.add(1, 'h').hour() &&
        item.value <= maxAvailableHour
      : item.value >= minAvailableHour && item.value <= maxAvailableHour
  )

  if (timeFrom) {
    hours = hours.filter((item: TPickerColumnData) =>
      timeFrom.minute() >= 45
        ? item.value > timeFrom.hour()
        : item.value >= timeFrom.hour()
    )
  }

  minutes = minutes.filter((item: TPickerColumnData) =>
    selectedHour === timeFrom.hour() && selectedHour !== maxDateTime.hour()
      ? item.value > timeFrom.minute()
      : selectedHour === maxDateTime.hour() && selectedHour === timeFrom.hour()
        ? item.value <= maxDateTime.minute() && item.value > timeFrom.minute()
        : true
  )

  return [hours, minutes]
}
