import { cx } from '@emotion/css'
import { TariffModel } from 'api/tariff/models/tariff.model.ts'
import { JSX } from 'react'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { Typography } from 'ui/typography'
import * as styles from './subscriptions.style.ts'

type TTariffButtonProps = {
  tariff: TariffModel
  isSelected: boolean
  onClick: VoidFunction
}

export function TariffButton({
  tariff,
  isSelected,
  onClick,
}: TTariffButtonProps): JSX.Element {
  return (
    <div className={styles.tariffButtonContainer}>
      <Typography size="13" weight="300" align="center">
        {tariff.periodLabel}
      </Typography>
      <CellEmpty height="2" />

      <button
        className={cx(styles.tariffButton, {
          [styles.tariffButtonSelected]: isSelected,
        })}
        onClick={onClick}
      >
        <Typography size="15" weight="300" lineHeight="0.3" align="right">
          {tariff.discountLabel}&nbsp;
        </Typography>
        {tariff.discountLabel && <CellEmpty height="5" />}
        <Typography size="30" weight="700" lineHeight="0.7">
          {tariff.pricePerMonth}
        </Typography>{' '}
        <CellEmpty height="3" />
        <Typography size="13" weight="300" lineHeight="0.7" align="right">
          руб/мес
        </Typography>
      </button>
    </div>
  )
}
