export enum SanekRoute {
  ROOT = '/',

  INTRO_REGISTER_SMS = '/intro/register-sms',
  INTRO_REGISTER_SMS_PHONE = '/intro/register-sms/phone',
  INTRO_LETS_TALK = '/intro/lets_talk',
  INTRO_SANEK_GREETING = '/intro/sanek_greeting',

  SURVEY_QUESTIONS = '/survey/questions',
  SURVEY_CUSTOMIZATION = '/survey/customization',
  SURVEY_ROLES = '/survey/roles',
  SURVEY_COMMON = '/survey/common',
  SURVEY_AVATARS = '/survey/avatars',

  MAIN = '/main',
  NEWS = '/news',
  NOTIFICATIONS = '/notifications',

  CREATE_MEETING = '/create-meeting',
  CREATE_MEETING_SUCCESS = '/create-meeting-success',
  MEETING_DETAILS = '/meeting-details',
  MEETING_FILTERS = '/meeting-filters',
  ACCEPT_INVITATION_SUCCESS = '/accept-invitation-success',
  MEETING_EVOLUTION_SUCCESS = '/meeting-evolution-success',

  MEETING_CHAT = '/meeting-chat',
  CREATE_CHAT_MESSAGE = '/create-chat-message',

  PLACES = '/places',
  PLACE_DETAILS = '/place-details',
  CREATE_PLACE = '/create-place',
  PLACES_MAP = '/places/map',

  INVITATIONS_LIST = '/invitations-list',
  INVITATIONS_FILTERS = '/invitations-filters',

  MEETING_FEEDBACK = '/meeting-feedback',
  MEETING_FEEDBACK_SUCCESS = '/meeting-feedback-success',

  PERSONAL = '/personal',
  PERSONAL_LEVELS = '/personal-levels',
  PERSONAL_CHANGE_CITY = '/personal/change-city',
  PERSONAL_MEETINGS_HISTORY = '/personal/meetings-history',
  GLOBAL_FEEDBACK = '/global-feedback',

  SUBSCRIPTIONS = '/subscriptions',
  SUBSCRIPTION_PROCEED = '/subscription-proceed',
  SUBSCRIPTION_BUY_SUCCESS = '/subscription-buy-success',
  SUBSCRIPTION_BUY_ERROR = '/subscription-buy-error',
  INSTRUCTION = '/instructions',
}

export const notAuthorizedRoutes = [
  SanekRoute.INTRO_REGISTER_SMS,
  SanekRoute.INTRO_REGISTER_SMS_PHONE,
]
