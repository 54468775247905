import { FC } from 'react'

import { SanekButton } from 'ui/sanek-button'
import { SVGImg } from 'ui/svg-img'

import * as styles from './instruction-info-popup.styles'
import sanekImage from './assets/imageSanekPopup.png'
import stripe from './assets/greenStripe.svg'

type InstrustionInfoPopupProps = {
  title: string
  description: string
  descriptionBold: string
  onConfirm: () => void
  onCancel: () => void
}

export const InstrustionInfoPopup: FC<InstrustionInfoPopupProps> = ({
  title,
  description,
  descriptionBold,
  onConfirm,
  onCancel,
}) => {
  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popup}>
        <div className={styles.image}>
          <img style={{ width: 90, height: 90 }} src={sanekImage} alt="" />
        </div>
        <div className={styles.title}>{title}</div>
        <SVGImg src={stripe} />
        <div className={styles.description}>{description}</div>
        <div className={styles.descriptionBold}>{descriptionBold}</div>
        <div className={styles.actions}>
          <SanekButton
            type="primary"
            onClick={onConfirm}
            className={styles.instruction}
          >
            Инструкция
          </SanekButton>
          <SanekButton className={styles.confirm} onClick={onCancel}>
            Понятно
          </SanekButton>
        </div>
      </div>
    </div>
  )
}
