import { LayoutPage } from 'components/layout-page'
import { FC } from 'react'

import { Typography } from 'ui/typography'
import { navigateBack } from 'utils/navigation'

import { InstructionSlider } from './components/instruction-slider/instruction-slider.component'
import { SVGImg } from 'ui/svg-img'

import stripe from 'assets/instruction/grey-stripe.svg'
import { SanekButton } from 'ui/sanek-button'

import * as styles from './instruction-page.styles'

export const Instruction: FC = () => {
  return (
    <LayoutPage backAction={navigateBack} headerText="Инструкция">
      <div className={styles.container}>
        <header
          style={{
            maxWidth: 310,
            margin: '0 auto',
          }}
        >
          <Typography size="18" weight="400">
            Необходимо сохранить / добавить сайт app.sanek.io на главный экран
            телефона
          </Typography>
          <div className={styles.questions}>
            <Typography size="20" weight="700" className={styles.questionsText}>
              Как это сделать?
            </Typography>
            <SVGImg src={stripe} />
          </div>
        </header>
        <InstructionSlider />
        <div className={styles.buttonContainer}>
          <SanekButton className={styles.button} onClick={navigateBack}>
            Поробовать
          </SanekButton>
        </div>
      </div>
    </LayoutPage>
  )
}
