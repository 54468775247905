import { css } from '@emotion/css'
import { maxLines } from 'styles/common.style.ts'

export const container = css`
  display: flex;
  flex-direction: column;
`

export const baseText = css`
  font-family: 'Copperplate', sans-serif;
  letter-spacing: -0.02em;
  color: #000;
`

export const fontStyling = (
  fontWeight: number,
  fontSize: number,
  lineHeight: number
) => css`
  font-weight: ${fontWeight};
  font-size: ${fontSize}px;
  line-height: ${lineHeight}%;
`

export const status = css`
  ${baseText};
  font-weight: 700;
  font-size: 14px;
  line-height: 86%;

  color: #68b984;
  margin-left: 40px;
`

export const card = css`
  width: 360px;
  height: 124px;
  border: 5px solid #f2f2f2;
  border-radius: 45px;
  box-shadow: 2px 3px 0 0 rgba(0, 0, 0, 0.25);
  background: #fafafa;
  display: flex;
  align-items: stretch;
  padding: 10px 0 10px 25px;
`

export const block = css`
  ${container};
  align-items: flex-start;
  width: 45%;
  gap: 10px;
`

export const placeBlock = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
`

export const icon = css`
  width: 52px;
  height: 52px;
`

export const time = css`
  ${baseText};
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 1px;
  text-align: center;
`

export const timePlural = css`
  ${baseText};
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  width: 110px;
`

export const date = css`
  ${baseText};
  font-weight: 400;
  font-size: 16px;
  line-height: 75%;
  text-align: center;
`

export const emojiBlock = css`
  display: flex;
  align-items: center;
  gap: 5px;
`

export const placeTitle = css`
  ${baseText};
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
`

export const address = css`
  ${baseText};
  ${maxLines(3)};
  font-weight: 300;
  font-size: 12px;
  line-height: 100%;
`

export const metro = css`
  ${baseText};
  font-weight: 300;
  font-size: 12px;
  line-height: 100%;
  align-self: flex-start;
  margin-top: 4px;

  span {
    margin-right: 5px;
  }
`

export const placeMarkWrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const placeMark = css`
  width: 30px;
  height: 20px;
  border-radius: 16px;
  background: #ffde00;
  display: flex;
  align-items: center;
  justify-content: center;
`
