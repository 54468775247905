import { subscriptionTransport } from 'api/subscription/subscription.transport.ts'
import { TariffModel } from 'api/tariff/models/tariff.model.ts'
import Splash from 'assets/decorations/subscriptions/splash.png'
import { CenteredLoader } from 'components/common/centered-loader'
import { LayoutPage } from 'components/layout-page'
import { SubscriptionStatus } from 'components/subscription-status'
import { observer } from 'mobx-react-lite'
import { TariffButton } from 'pages/subscriptions/tariff-button.tsx'
import { JSX, useEffect, useState } from 'react'
import { router } from 'router'
import { SanekRoute } from 'router/router.enums.ts'
import { tariffStore } from 'store/tariff.store.ts'
import { userStore } from 'store/user.store.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { FlexContainer } from 'ui/flex-container'
import { SanekButton } from 'ui/sanek-button'
import { Typography } from 'ui/typography'
import { navToMainPage } from 'utils/navigation.ts'
import * as styles from './subscriptions.style.ts'
import { GetPaymentResponse } from 'api/payment/responses/get-payment.response.ts'
import { paymentTransport } from 'api/payment/payment.transport.ts'
import { BuySubscriptionResponse } from 'api/subscription/responses/buy-subscription.response.ts'
import { SeparatorGrayWide } from 'components/common/separator'

export const Subscriptions = observer((): JSX.Element => {
  const { user } = userStore
  const { tariffs, isLoading } = tariffStore
  const [selectedTariff, setSelectedTariff] = useState<TariffModel | null>(null)
  const [isBuying, setIsBuying] = useState(false)
  const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false)

  useEffect(() => {
    tariffStore.getTariffs()
  }, [])

  useEffect(() => {
    // Если во время последнего платежа как-то криво закрыли страницу (не через кнопку) или закрыли приложение
    // актуализируем информацию при заходе
    if (user?.latestPayment && user.latestPayment.data.status === 'pending') {
      setIsPaymentLoading(true)
      void paymentTransport
        .getPayment(user.latestPayment.id)
        .then(({ payment }: GetPaymentResponse) => {
          if (payment && payment.data.status === 'succeeded') {
            void router.navigate(SanekRoute.SUBSCRIPTION_BUY_SUCCESS)
          }
        })
        .finally(() => setIsPaymentLoading(false))
    }
  }, [user])

  const handleTariffSelect = (tariff: TariffModel): void => {
    setSelectedTariff(tariff)
  }

  const handleBuySubscription = (): void => {
    if (!selectedTariff) {
      return
    }

    setIsBuying(true)
    subscriptionTransport
      .buySubscription(selectedTariff.id)
      .then(({ paymentId }: BuySubscriptionResponse) => {
        void router.navigate(
          `${SanekRoute.SUBSCRIPTION_PROCEED}?paymentId=${paymentId}`
        )
      })
      .catch(() => {
        void router.navigate(SanekRoute.SUBSCRIPTION_BUY_ERROR)
      })
      .finally(() => setIsBuying(false))
  }

  if (isLoading || isPaymentLoading) {
    return <CenteredLoader />
  }

  return (
    <LayoutPage headerText="Подписка" backAction={navToMainPage}>
      <div className={styles.subscriptionsPage}>
        <CellEmpty height="15" />

        {user?.subscription && (
          <>
            <SubscriptionStatus subscription={user.subscription} />
            <CellEmpty height="20" />
          </>
        )}

        <Typography family="Chalkboard" size="24">
          Выбери подписку
        </Typography>
        <CellEmpty height="20" />

        <FlexContainer justify="space-between">
          {tariffs.map((tariff: TariffModel) => (
            <TariffButton
              key={tariff.id}
              tariff={tariff}
              isSelected={selectedTariff?.id === tariff.id}
              onClick={handleTariffSelect.bind(null, tariff)}
            />
          ))}
        </FlexContainer>
        <CellEmpty height="23" />

        <SanekButton
          isLoading={isBuying}
          disabled={!selectedTariff}
          onClick={handleBuySubscription}
        >
          КУПИТЬ ПОДПИСКУ
        </SanekButton>
        <CellEmpty height="36" />

        <SeparatorGrayWide />
        <CellEmpty height="40" />

        <Typography family="Chalkboard" size="24">
          Что дает подписка?
          <img src={Splash} alt="" className={styles.splash} />
        </Typography>
        <CellEmpty height="20" />
        <div style={{ paddingLeft: '25px' }}>
          <Typography>Неограниченное использование приложения</Typography>
        </div>
        <CellEmpty height="40" />

        <SeparatorGrayWide />
        <CellEmpty height="40" />

        <Typography family="Chalkboard" size="24">
          Что если нет подписки?
        </Typography>
        <CellEmpty height="20" />
        <div style={{ paddingLeft: '25px' }}>
          <Typography>
            Всем новым пользователям мы даем 1 месяц бесплатного использования
            приложения
          </Typography>
          <CellEmpty height="15" />
          <Typography>
            Чтобы пользоваться приложением после истечения 1 месяца потребуется
            подписка
          </Typography>
        </div>
      </div>
    </LayoutPage>
  )
})
