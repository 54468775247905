import { FC } from 'react'

import * as styles from './instruction-slide.styles'

interface InstructionSlide {
  description: string
  image: string
}

export const InstructionSlide: FC<InstructionSlide> = ({
  image,
  description,
}) => {
  return (
    <div className={styles.slideContainer}>
      <div>
        <img src={image} alt="slide" className={styles.image} />
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  )
}
