import { FC, useRef } from 'react'

import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'
import { Autoplay, Mousewheel, Navigation, Pagination } from 'swiper/modules'

import { InstructionSlide } from '../instruction-slide/instruction-slide.component'
import { getSliderItems } from '../../utils/getSliderItems'

export const InstructionSlider: FC = () => {
  const swiperRef = useRef<SwiperRef | null>(null)

  const slides = getSliderItems()

  return (
    <Swiper
      ref={swiperRef}
      loop={false}
      initialSlide={0}
      modules={[Navigation, Autoplay, Pagination, Mousewheel]}
      slidesPerView={1}
      speed={400}
      scrollbar={{ draggable: true }}
      pagination={{
        renderBullet: (_, className) => {
          return '<span class="' + className + '">' + '</span>'
        },
      }}
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          <InstructionSlide {...slide} />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
