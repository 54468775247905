import slide1 from 'assets/instruction/android/slide-1.png'
import slide2 from 'assets/instruction/android/slide-2.png'
import slide3 from 'assets/instruction/android/slide-3.png'
import slide4 from 'assets/instruction/android/slide-4.png'
import slide5 from 'assets/instruction/android/slide-5.png'
import slide6 from 'assets/instruction/android/slide-6.png'
import { SliderItem } from '../type'

export const ANDROID_SLIDER_ITEMS: SliderItem[] = [
  {
    image: slide1,
    description: 'Перейдите на сайт app.sanek.io',
  },
  {
    image: slide2,
    description: 'Откройте “Боковую панель” браузера',
  },
  {
    image: slide3,
    description: 'Нажмите “Добавить на гл. экран”',
  },
  {
    image: slide4,
    description: 'Нажмите “Установить”',
  },
  {
    image: slide5,
    description: 'Нажмите “Установить”',
  },
  {
    image: slide6,
    description: 'Ура! Приложение утсановлено и добавлено на экран “Домой”',
  },
]
