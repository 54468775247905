import { css } from '@emotion/css'

export const container = css`
  padding: 15px;
  margin: 0 auto;
`

export const button = css`
  margin-top: 15px;
  max-width: 320px;
`
export const buttonContainer = css`
  display: flex;
  align-items: center;
`

export const questions = css`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

export const questionsText = css`
  text-align: center;
`
