import Copy from 'assets/copy.svg'
import DetailsDivider from 'assets/decorations/places/details-divider.svg'
import Geolocation from 'assets/geolocation.svg'
import HrYellow from 'assets/hr-yellow.svg'
import { CenteredLoader } from 'components/common/centered-loader'
import { LayoutPage } from 'components/layout-page'
import { PRICE_SEGMENT_LABEL_MAP } from 'const/places.const.ts'
import { observer } from 'mobx-react-lite'
import { PlaceDetailsImages } from 'pages/places/place-details/place-details-images.tsx'
import { JSX, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { router } from 'router'
import { SanekRoute } from 'router/router.enums.ts'
import { placeDetailsStore } from 'store/place-details.store.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { FlexContainer } from 'ui/flex-container'
import { SanekButton } from 'ui/sanek-button'
import { Typography } from 'ui/typography'
import { showCopyAlert } from 'utils/alert.ts'
import { copyToClipboard } from 'utils/clipboard.ts'

import * as styles from './place-details.style.ts'
import { meetingCreationStore } from 'store/meeting-creation.store.ts'
import { SVGImg } from 'ui/svg-img'
import HeartFilled from 'assets/decorations/places/heart-filled.svg'
import HeartGrey from 'assets/decorations/places/heart-grey.svg'
import { sanekUsersTransport } from 'api/sanek-users.transport.ts'
import { userStore } from 'store/user.store.ts'
import { placesStore } from 'store/places.store.ts'

type TPlaceDetailsParams = {
  placeId: string
}

export const PlaceDetails = observer((): JSX.Element => {
  const { placeId } = useParams<TPlaceDetailsParams>()
  const { state } = useLocation()
  const { user } = userStore
  const { setIsFavorite } = placesStore
  const { place, setPlaceFavourite, isLoading } = placeDetailsStore

  const navigate = useNavigate()

  const hasCoords = Number(place?.longitude) && Number(place?.latitude)

  useEffect(() => {
    if (!placeId) {
      return
    }

    placeDetailsStore.getPlace(placeId)
  }, [placeId])

  if (isLoading || !place) {
    return <CenteredLoader />
  }

  const handleCopyAddress = (): void => {
    copyToClipboard(place.address).then(showCopyAlert)
  }

  const handleOpenMapClick = (): void => {
    if (!hasCoords) return
    navigate(
      `/places/map?latitude=${place.latitude}&longitude=${place.longitude}&zoom=14`
    )
  }

  const handleWebsiteClick = (): void => {
    window.open(place.website)
  }

  const handleChoosePlace = (): void => {
    meetingCreationStore.setSelectedPlace(place)
    meetingCreationStore.setSelectedCategory(place.categories[0])
    void router.navigate(SanekRoute.CREATE_MEETING)
  }

  const handleFavouriteClick = () => {
    if (!user) {
      return
    }

    const isFavourite = place.isFavourite

    if (!isFavourite) {
      setPlaceFavourite(true)
      setIsFavorite(place.id, true)
      sanekUsersTransport.addPlaceToFavourites(user.id, place.id).catch(() => {
        setPlaceFavourite(false)
        setIsFavorite(place.id, false)
      })
      return
    }

    setPlaceFavourite(false)
    setIsFavorite(place.id, false)
    sanekUsersTransport
      .removePlaceFromFavourites(user.id, place.id)
      .catch(() => {
        setIsFavorite(place.id, true)
        setPlaceFavourite(true)
      })
  }

  return (
    <LayoutPage backAction={() => navigate(-1)}>
      <div className={styles.placeDetailsPage}>
        <div className={styles.placeDetailsCard}>
          <button
            className={styles.placeFavouriteButton}
            onClick={handleFavouriteClick}
          >
            <img src={place.isFavourite ? HeartFilled : HeartGrey} />
          </button>
          <div className={styles.priceSegment}>
            {PRICE_SEGMENT_LABEL_MAP[place.priceSegment]}
          </div>

          <Typography
            styles={{ maxWidth: '300px' }}
            type="h2"
            family="Chalkboard"
            size="24"
            align="center"
          >
            {place.name}
          </Typography>
          <img src={HrYellow} className={styles.hrImg} />

          <Typography size="14" weight="300">
            {place.description}
          </Typography>
          <CellEmpty height="15" />
          <FlexContainer justify="space-between" wrap="nowrap">
            <Typography size="12" weight="300">
              {place.address}
            </Typography>
            <div className={styles.copyButtonWrapper}>
              <button
                className={styles.copyButton}
                onClick={handleCopyAddress}
              ></button>
              <SVGImg src={Copy} />
            </div>
          </FlexContainer>
          <CellEmpty height="10" />
          <Typography size="12" weight="300">
            <span
              className={styles.getMetroLetterStyle(place.metroStation.color)}
            >
              M
            </span>
            <span>{place.metroStation.name}</span>
          </Typography>
          <CellEmpty height="5" />
          <img src={DetailsDivider} className={styles.detailsDivider} alt="" />
          <CellEmpty height="5" />
          <FlexContainer justify="space-between" align="center" wrap="nowrap">
            {hasCoords ? (
              <button
                className={styles.geolocationButton}
                onClick={handleOpenMapClick}
              >
                <img
                  src={Geolocation}
                  className={styles.geolocationImage}
                  alt="Локация"
                />
                <Typography size="12" styles={{ width: '55px' }}>
                  Показать на&nbsp;карте
                </Typography>
              </button>
            ) : (
              <div>Координаты не указаны</div>
            )}
            <FlexContainer direction="column" align="center">
              <Typography size="14">{place.phone}</Typography>
              <CellEmpty height="10" />
              <Typography size="10">Забронировать</Typography>
            </FlexContainer>
          </FlexContainer>
          <CellEmpty height="10" />
          {place.website && (
            <>
              <button
                className={styles.websiteButton}
                onClick={handleWebsiteClick}
              >
                Сайт заведения
              </button>
              <CellEmpty height="10" />
            </>
          )}
          <PlaceDetailsImages place={place} />
        </div>
        <CellEmpty height="15" />

        {!state?.isOnlyView && (
          <div className={styles.buttonSaveContainer}>
            <SanekButton onClick={handleChoosePlace}>ВЫБРАТЬ</SanekButton>
          </div>
        )}
      </div>
    </LayoutPage>
  )
})
