import { UserMeetingModel } from 'api/user-meetings/models/user-meeting.model.ts'
import Catalog from 'assets/catalog.png'
import Geolocation from 'assets/geolocation.svg'
import Globe from 'assets/globe.png'
import { WebsiteMissingDialog } from 'dialogs/website-missing-dialog'
import { JSX } from 'react'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { FlexContainer } from 'ui/flex-container'
import { Typography } from 'ui/typography'
import { getFormatTimeMessage } from 'utils/date.tsx'
import { openDialog } from 'utils/dialog.ts'
import { navToPlaceDetails } from 'utils/navigation.ts'
import { openYandexMapOrg } from 'utils/yandex.ts'
import * as styles from './meeting-details-card.style.ts'
import { PlaceDetailsButton } from './place-details-button.tsx'

type TMeetingCardProps = {
  meeting: UserMeetingModel
}

export function MeetingDetailsCard({
  meeting,
}: TMeetingCardProps): JSX.Element {
  const { place } = meeting

  const handleOpenMapClick = (): void => {
    openYandexMapOrg(place.yandexOrgId)
  }

  const handleOpenPlaceWebsite = (): void => {
    if (place.website) {
      window.open(place.website)
    } else {
      openDialog({
        content: <WebsiteMissingDialog />,
      })
    }
  }

  const handleOpenPlaceDetails = (): void => {
    navToPlaceDetails(place.id, true)
  }

  return (
    <div className={styles.getMeetingCardStyles(meeting.isCreator)}>
      <FlexContainer justify="space-between" wrap="nowrap">
        <div className={styles.placeNameBox}>
          <p className={styles.placeNameText}>{place.name}</p>
        </div>
        <div className={styles.placeLinkBox}>
          {place.isCreated ? (
            <PlaceDetailsButton
              icon={Globe}
              text="САЙТ"
              onClick={handleOpenPlaceWebsite}
            />
          ) : (
            <PlaceDetailsButton
              icon={Catalog}
              text="КАТАЛОГ"
              onClick={handleOpenPlaceDetails}
            />
          )}
        </div>
      </FlexContainer>
      <CellEmpty height="12" />

      <FlexContainer justify="space-between" wrap="nowrap">
        <div className={styles.meetingTimeBox}>
          {meeting.confirmedTimeslot ? (
            <>
              <Typography
                size="14"
                weight="700"
                align="center"
                styles={{ letterSpacing: 1 }}
              >
                {getFormatTimeMessage(
                  meeting.confirmedTimeslot.timeFrom,
                  meeting.confirmedTimeslot.timeTo
                )}
              </Typography>
              <CellEmpty height="10" />

              <Typography size="14" weight="400" align="center">
                {meeting.confirmedTimeslot.date.format('dddd')}
                <br />
                {meeting.confirmedTimeslot.date.format('D MMMM')}
              </Typography>
            </>
          ) : (
            <Typography size="20" weight="700" align="center">
              Нет времени
            </Typography>
          )}
        </div>
        <div className={styles.placeAddressBox}>
          <FlexContainer justify="space-between" align="center" wrap="nowrap">
            <p className={styles.addressText}>{place.address}</p>
            <button
              className={styles.geolocationButton}
              onClick={handleOpenMapClick}
            >
              <img src={Geolocation} className={styles.geolocationImage} />
            </button>
          </FlexContainer>
          <CellEmpty height="10" />

          <Typography size="14" weight="300">
            <Typography
              type="span"
              size="14"
              weight="700"
              color={place.metroStation.color}
            >
              M&nbsp;
            </Typography>
            {place.metroStation.name}
          </Typography>
        </div>
      </FlexContainer>
    </div>
  )
}
