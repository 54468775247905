import { Circle, Map, YMaps } from '@pbe/react-yandex-maps'
import { Toast } from 'antd-mobile'
import mapLocationSvg from 'assets/map-location.svg'
import minusMapSvg from 'assets/minus-map.svg'
import plusMapSvg from 'assets/plus-map.svg'
import { MicrophonePermissionPopup } from 'components/audio-recorder/microphone-popup/microphone-permission-popup.tsx'
import { LayoutPage } from 'components/layout-page'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { placesStore } from 'store/places.store'
import { userStore } from 'store/user.store'
import { navToPlaces } from 'utils/navigation'
import { Map as MapType } from 'yandex-maps'
import CustomPlacemark from './custom-placemark/custom-placemark.tsx'
import * as styles from './places-map.style'
import CustomPlacemarkGrey from './custom-placemark/custom-placemark-grey.tsx'

const ZOOM_DELTA = 1
const ZOOM_DEFAULT = 13
const DETECTED_USER_ZOOM = 16
const ANIMATION_DURATION = 300
const DEFAULT_CENTER = [55.755864, 37.617698]

export const PlacesMap: FC = observer(() => {
  const { user } = userStore
  const { places, getPlaces } = placesStore

  const [userPosition, setUserPosition] = useState<number[]>([])

  const [isShowGeolocationPopup, setIsShowGeolocationPopup] = useState(false)

  const hasDetectedUserPosition = userPosition.length === 2

  const [searchParams] = useSearchParams()

  const latitudeFromParams = Number(searchParams.get('latitude'))
  const longitudeFromParams = Number(searchParams.get('longitude'))
  const zoomFromParams = Number(searchParams.get('zoom'))

  const hasSearchParams = latitudeFromParams && longitudeFromParams

  const userCity = user?.city
  const mapRef = useRef<MapType | undefined>()

  const placesWithLocation = places.filter(
    (place) => place.latitude && place.longitude
  )

  const highlightedPlaceId = hasSearchParams
    ? placesWithLocation.find(
        (place) =>
          // @ts-ignore
          Math.abs(place.latitude - latitudeFromParams) < 0.0001 &&
          // @ts-ignore
          Math.abs(place.longitude - longitudeFromParams) < 0.0001
      )?.id
    : null

  useEffect(() => {
    getPlaces()
  }, [])

  const zoomIn = () => {
    if (!mapRef.current) return
    const currentZoom = mapRef.current?.getZoom()
    mapRef.current.setZoom(currentZoom + ZOOM_DELTA, {
      duration: ANIMATION_DURATION,
    })
  }

  const zoomOut = () => {
    if (!mapRef.current) return
    const currentZoom = mapRef.current?.getZoom()
    mapRef.current.setZoom(currentZoom - ZOOM_DELTA, {
      duration: ANIMATION_DURATION,
    })
  }

  const handleLoadMap = () => {
    if (hasSearchParams) {
      setMapPosition(latitudeFromParams, longitudeFromParams, zoomFromParams)
    } else {
      detectCurrentPosition()
    }
  }

  const setMapPosition = (
    latitude: number,
    longitude: number,
    zoom: number = ZOOM_DEFAULT
  ) => {
    mapRef.current?.setCenter([latitude, longitude], zoom, {
      duration: ANIMATION_DURATION,
    })
  }
  const detectCurrentPosition = () => {
    if (!mapRef.current) return

    navigator.permissions
      .query({ name: 'geolocation' })
      .then((permissionStatus) => {
        switch (permissionStatus.state) {
          case 'granted':
            navigator.geolocation.getCurrentPosition(
              (position) => {
                setUserPosition([
                  position.coords.latitude,
                  position.coords.longitude,
                ])
                setMapPosition(
                  position.coords.latitude,
                  position.coords.longitude,
                  DETECTED_USER_ZOOM
                )
              },
              () => {
                Toast.show('Не смогли определить твою локацию')
                setDefaultMapPosition()
              }
            )
            break

          case 'denied':
            setIsShowGeolocationPopup(true) // Показываем попап, если доступ заблокирован

            break

          case 'prompt':
            navigator.geolocation.getCurrentPosition(
              (position) => {
                setUserPosition([
                  position.coords.latitude,
                  position.coords.longitude,
                ])
                setMapPosition(
                  position.coords.latitude,
                  position.coords.longitude,
                  DETECTED_USER_ZOOM
                )
              },
              () => {
                setIsShowGeolocationPopup(true) // Показываем попап
              }
            )
            break

          default:
            Toast.show('Невозможно определить состояние разрешений')
        }
      })
      .catch((error) => {
        console.error('Ошибка доступа к API разрешений:', error)
        Toast.show('Ошибка доступа к API разрешений')
      })
  }
  const setDefaultMapPosition = () => {
    const hasUserCityParams = userCity?.latitude && userCity?.longitude
    const center = hasUserCityParams
      ? [Number(userCity.latitude), Number(userCity.longitude)]
      : DEFAULT_CENTER

    mapRef.current?.setCenter(center, mapRef.current?.getZoom(), {
      duration: ANIMATION_DURATION,
    })
  }

  return (
    <LayoutPage backAction={navToPlaces} headerText="Выбрать место">
      <div className={styles.mapWrapper}>
        <YMaps query={{ apikey: import.meta.env.VITE_YANDEX_MAPS_API_KEY }}>
          <Map
            onLoad={handleLoadMap}
            instanceRef={mapRef}
            state={{ center: DEFAULT_CENTER, zoom: ZOOM_DEFAULT }}
            className={styles.map}
          >
            {placesWithLocation.map((place) =>
              highlightedPlaceId ? (
                place.id === highlightedPlaceId ? (
                  <CustomPlacemark
                    key={place.id}
                    place={place}
                    mapRef={mapRef}
                  />
                ) : (
                  <CustomPlacemarkGrey
                    key={place.id}
                    place={place}
                    mapRef={mapRef}
                  />
                )
              ) : (
                <CustomPlacemark key={place.id} place={place} mapRef={mapRef} />
              )
            )}
            {hasDetectedUserPosition && (
              <Circle
                geometry={[userPosition, 10]}
                options={{
                  draggable: false,
                  fillColor: '#FFDE00',
                  strokeColor: '#000000',
                  strokeOpacity: 1,
                  strokeWidth: 4,
                }}
              />
            )}
          </Map>
        </YMaps>

        <div className={styles.plusButton} onClick={zoomIn}>
          <img src={plusMapSvg} alt="Plus" />
        </div>
        <div className={styles.minusButton} onClick={zoomOut}>
          <img src={minusMapSvg} alt="Minus" />
        </div>
        {isShowGeolocationPopup && (
          <MicrophonePermissionPopup
            title="Разрешить доступ к геолокации"
            description="Для отображения вашей текущей локации на карте необходимо разрешить доступ к геолокации."
            onConfirm={() => {
              setIsShowGeolocationPopup(false)
              detectCurrentPosition()
            }}
            onCancel={() => {
              setIsShowGeolocationPopup(false)
            }}
          />
        )}
        <div className={styles.zoomButton} onClick={detectCurrentPosition}>
          <img src={mapLocationSvg} alt="Location" />
        </div>
      </div>
    </LayoutPage>
  )
})
