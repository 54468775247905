import { MeetingCategoryModel } from 'api/meeting/models/meeting-category.model.ts'
import { MetroStationModel } from 'api/models/metro-station.model.ts'
import { CreatePlaceRequest } from 'api/places/models/create-place.request.ts'
import { PlaceModel } from 'api/places/models/place.model.ts'
import { placesTransport } from 'api/places/places.transport.ts'
import TipQuestion from 'assets/tip-question.svg'
import { AddPointMapModal } from 'components/add-point-map-modal/add-point-map-modal.tsx'
import { LayoutPage } from 'components/layout-page'
import { MetroStationSelectDialog } from 'components/metro-station-dialog'
import { PriceSegmentRadio } from 'components/price-segment-radio'
import { PRICE_SEGMENT_OPTIONS } from 'const/places.const.ts'
import { PriceSegment } from 'enums/places.enums.ts'
import { observer } from 'mobx-react-lite'
import { ChangeEvent, JSX, useMemo, useState } from 'react'
import { router } from 'router'
import { SanekRoute } from 'router/router.enums.ts'
import { meetingCategoriesStore } from 'store/meeting-categories.store.ts'
import { meetingCreationStore } from 'store/meeting-creation.store.ts'
import { userStore } from 'store/user.store.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { CenteredInput } from 'ui/centered-input'
import { FlexContainer } from 'ui/flex-container'
import { FormItem } from 'ui/form-item'
import { TRadioOption } from 'ui/radio'
import { ResetButton } from 'ui/reset-button'
import { SanekButton } from 'ui/sanek-button'
import { openDialog } from 'utils/dialog.ts'
import { navigateBack } from 'utils/navigation.ts'
import * as styles from './create-place.style.ts'
import { PlaceCategory } from './place-category'
import ScrollToTop from 'widgets/ScrolToTop/ScrolToTop.tsx'

type TCreatePlaceForm = {
  name: string
  address: string
  point: string
  metroStation: MetroStationModel | null
  segment: PriceSegment
  website: string
  category: MeetingCategoryModel | null
}

const DEFAULT_CREATE_PLACE_FORM: TCreatePlaceForm = {
  name: '',
  address: '',
  point: '',
  metroStation: null,
  segment: PriceSegment.MEDIUM,
  website: '',
  category: null,
}

export const CreatePlacePage = observer((): JSX.Element => {
  const { categories } = meetingCategoriesStore
  const { user } = userStore
  const [form, setForm] = useState<TCreatePlaceForm>(DEFAULT_CREATE_PLACE_FORM)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const handleSubmit = (): void => {
    if (!isFormFilled) {
      return
    }

    setIsProcessing(true)

    const website = form.website.match(/https?:\/\/[^\s]+\/[0-9]+/)

    const request = new CreatePlaceRequest({
      name: form.name,
      address: form.address,
      website: website ? website[0] : '',
      yandexOid: form.point.split('\n')[2],
      priceSegment: form.segment,
      categoryId: form.category!.id,
      metroStationId: form.metroStation!.id,
      creatorUserId: userStore.user?.id,
    })

    void placesTransport
      .createPlace(request)
      .then((data: PlaceModel) => {
        meetingCreationStore.setSelectedPlace(data)
        meetingCreationStore.setSelectedCategory(data.categories[0])
        void router.navigate(SanekRoute.CREATE_MEETING)
      })
      .finally(() => setIsProcessing(false))
  }

  const updateForm = (value: Partial<TCreatePlaceForm>): void => {
    setForm((prevState: TCreatePlaceForm) => ({
      ...prevState,
      ...value,
    }))
  }

  const isFormFilled = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { website, ...requiredData } = form
    return Object.values(requiredData).every(
      (value: string | MeetingCategoryModel | MetroStationModel | null) =>
        Boolean(value)
    )
  }, [form])

  const handleInputChange = ({
    target,
  }: ChangeEvent<HTMLInputElement>): void => {
    const { id, value } = target
    updateForm({ [id]: value })
  }

  const handleSelectSegment = (event: ChangeEvent<HTMLInputElement>): void => {
    updateForm({ segment: event.target.value as PriceSegment })
  }

  const handleSelectCategory = (category: MeetingCategoryModel): void => {
    updateForm({ category })
  }

  const handleChangeMetroStation = (metroStation: MetroStationModel): void => {
    updateForm({ metroStation })
  }

  const handleOpenMetroStationDialog = (): void => {
    openDialog({
      content: (
        <MetroStationSelectDialog
          cityId={user?.city?.id}
          onSelect={handleChangeMetroStation}
        />
      ),
    })
  }

  const handleAddPointMapDialog = (): void => {
    openDialog({
      content: <AddPointMapModal />,
    })
  }

  const handleResetForm = (): void => {
    setForm(DEFAULT_CREATE_PLACE_FORM)
  }

  return (
    <LayoutPage headerText="Добавить место" backAction={navigateBack}>
      <div className={styles.createPlacePage}>
        <ScrollToTop />
        <ResetButton onClick={handleResetForm} />
        <FormItem>
          <label htmlFor="name">Название места</label>
          <CenteredInput
            value={form.name}
            placeholder="Введите название места"
            id="name"
            onChange={handleInputChange}
          />
        </FormItem>

        <FormItem>
          <label htmlFor="address">Адрес места</label>
          <CenteredInput
            value={form.address}
            placeholder="Введите полный адрес места"
            id="address"
            onChange={handleInputChange}
          />
        </FormItem>

        <FormItem>
          <label htmlFor="point">
            <FlexContainer align="center">
              <p style={{ marginBottom: '4px' }}>Точка на Яндекс карте</p>
              <img
                src={TipQuestion}
                className={styles.tip}
                onClick={handleAddPointMapDialog}
              />
            </FlexContainer>
          </label>
          <CenteredInput
            value={form.point}
            placeholder="Ссылка на геолокацию места"
            id="point"
            onChange={handleInputChange}
          />
        </FormItem>

        <FormItem>
          <label htmlFor="point">Ближайшее метро</label>
          <CenteredInput
            readOnly
            value={form.metroStation?.name}
            placeholder="Выберите метро"
            onClick={handleOpenMetroStationDialog}
          />
        </FormItem>

        <FormItem>
          <label htmlFor="segment">Ценовой сегмент</label>
          <div className={styles.priceSegments}>
            {PRICE_SEGMENT_OPTIONS.map((option: TRadioOption<PriceSegment>) => (
              <PriceSegmentRadio
                key={option.value}
                option={option}
                checked={form.segment === option.value}
                withDescription
                onChange={handleSelectSegment}
              />
            ))}
          </div>
        </FormItem>

        <FormItem>
          <label htmlFor="website">Сайт места</label>
          <CenteredInput
            value={form.website}
            placeholder="Добавьте ссылку на сайт"
            id="website"
            onChange={handleInputChange}
          />
        </FormItem>

        <>
          <label htmlFor="place-category">Тип места</label>
          <CellEmpty height="12" />

          <div className={styles.placeCategoriesWrapper}>
            {categories.map((category: MeetingCategoryModel) => (
              <PlaceCategory
                category={category}
                key={category.id}
                isSelected={form.category?.id === category.id}
                onClick={handleSelectCategory}
              />
            ))}
          </div>
          <CellEmpty height="26" />
        </>

        {!isFormFilled && <p className={styles.formAlert}>Заполни все поля</p>}
        <SanekButton
          disabled={!isFormFilled}
          onClick={handleSubmit}
          isLoading={isProcessing}
        >
          ДОБАВИТЬ МЕСТО
        </SanekButton>
      </div>
    </LayoutPage>
  )
})
