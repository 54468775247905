import { css } from '@emotion/css'

export const popupOverlay = css`
  width: 100%;
  position: fixed;
  z-index: 999;
  background: rgba(162, 158, 158, 0.3);
  height: 100vh;
`

export const popup = css`
  position: fixed;
  z-index: 1000;

  height: 402px;
  top: 30%;
  left: 32px;
  right: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 16px;
  border: 2px solid #ffde00;
`
export const image = css`
  margin-top: 20px;

  border-radius: 100%;
  background: #ffde00;
  display: flex;

  align-items: center;
  justify-content: center;
`
export const title = css`
  font-family:
    Chalkboard SE,
    serif;
  text-align: center;
  margin-top: 4px;
  font-size: 24px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 10px;
`

export const divider = css`
  margin: 12px 0;
  width: 132px;
  height: 3px;
  border-radius: 2px;
  background: #68b984;
`

export const description = css`
  padding: 0 24px;
  text-align: center;
  font-size: 18px;
  line-height: 18px;
  margin-top: 12px;
`
export const descriptionBold = css`
  padding: 0 24px;
  font-weight: 700;
  text-align: center;
  font-size: 18px;
  line-height: 18px;
  margin-top: 12px;
`

export const content = css`
  padding: 0 24px;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  margin-top: 12px;
`
export const actions = css`
  margin: 24px auto;
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  button {
    color: black;
    padding: 0;
    font-weight: bold;
    width: 276px;
    border-radius: 30px;
  }
`

export const instruction = css`
  font-weight: 700;
  height: 32px;
  font-size: 18px;
  line-height: 12px;
  box-shadow: 1px 2px 0 rgba(0, 0, 0, 0.25);
  background: #68b984;
  border: none;
`

export const confirm = css`
  font-size: 24px;
  line-height: 18px;
  height: 44px;
  box-shadow: 1px 2px 0 rgba(0, 0, 0, 0.25);
`
export const info = css`
  white-space: pre-wrap;
`
