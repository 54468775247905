import { css } from '@emotion/css'

export const slideContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const image = css`
  width: 273px;
  height: 591px;
  margin-bottom: 12px;
`
export const description = css`
  width: 245px;
  margin-bottom: 30px;

  font-family: 'Copperplate', sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 107%;
  letter-spacing: -0.03em;
  color: #000;
`
